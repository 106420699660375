import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import FeaturesSection from '../components/home/sections/Features';
import FiscalHostSection from '../components/home/sections/FiscalHost';
import JoinUsSection from '../components/home/sections/JoinUs';
import LearnMoreSection from '../components/home/sections/LearnMore';
import MakeCommunitySection from '../components/home/sections/MakeCommunity';
import OCUsersSection from '../components/home/sections/OCUsers';
import WeAreOpenSection from '../components/home/sections/WeAreOpen';
import WhatCanYouDoSection from '../components/home/sections/WhatCanYouDo';
import Page from '../components/Page';

const menuItems = { pricing: true, howItWorks: true };

const messages = defineMessages({
  defaultTitle: {
    id: 'OC.tagline',
    defaultMessage: 'Act 22/60 Non Profit Donation Platform.',
  },
});

const HomePage = () => {
  const { formatMessage } = useIntl();
  return (
    <Page menuItems={menuItems} description={formatMessage(messages.defaultTitle)}>
      <MakeCommunitySection />
      {/* <WhatCanYouDoSection /> */}
      <OCUsersSection />
      <FeaturesSection />
      <FiscalHostSection />
      {/* <WeAreOpenSection /> */}
      <LearnMoreSection />
      <JoinUsSection />
    </Page>
  );
};

export default HomePage;
